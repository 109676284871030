






































import { OrganizationUser } from '@/@types/organization.d';
import { ApiStatus } from '@/utils/const';
import { defineComponent, PropType, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'StakeholdersRolesDialog',

  props: {
    user: {
      type: Object as PropType<OrganizationUser | null>,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    orgRoles: {
      type: Array,
      default: () => {}
    }
  },

  setup(props, { emit }) {
    console.log(props);
    // const roles = ref([
    //   {
    //     label: 'Owner',
    //     value: 'owner'
    //   },
    //   {
    //     label: 'Admin',
    //     value: 'admin'
    //   },
    //   {
    //     label: 'Manager',
    //     value: 'manager'
    //   }
    // ]);
    const roles = ref(props?.orgRoles);

    const selectedRole = ref(props.user?.role);

    const handleApplyNewRole = () => {
      emit('update-role', selectedRole.value);
    };
    return {
      handleApplyNewRole,
      roles,
      selectedRole,
      ApiStatus
    };
  }
});
