





























































































import { computed, ref, Ref, watch, watchEffect } from '@vue/composition-api';
import { useUserGetters, useUserActions } from '@/store';
import CitizenId, { components as citizenComponents } from './views/CitizenID.vue';

export default {
  name: 'Portfolio',

  components: {
    MyPrograms: () => import('@/views/Portfolio/views/MyPrograms.vue'),
    CitizenId,
    Balance: () => import('@/views/Portfolio/views/Balance.vue'),
    Payment: () => import('@/views/Portfolio/views/Payment.vue'),
    Referral: () => import('@/views/Portfolio/views/Referral.vue'),
    Loading: () => import('@/components/Loading.vue'),
    Settings: () => import('@/views/Portfolio/views/Settings.vue'),
    Badges: () => import('@/views/Portfolio/views/Badges.vue'),
    Profile: () => import('@/components/Profile.vue')
  },

  props: {
    isWallet: {
      type: Boolean,
      default: false
    },
    isPortfolio: {
      type: Boolean,
      default: false
    },
    isPayment: {
      type: Boolean,
      default: false
    },
    isSetting: {
      type: Boolean,
      default: false
    },
    isBadges: {
      type: Boolean,
      default: false
    },
    isCitizen: {
      type: Boolean,
      default: false
    }
  },

  setup(_props: any, context: any) {
    const { getUser } = useUserGetters(['getUser']);
    const { getTokens, totalOrganizationTokens } = useUserActions([
      'getTokens',
      'totalOrganizationTokens'
    ]);
    const user: any = computed({
      get: () => getUser.value,
      set: newVal => {
        context.emit('input', newVal);
      }
    });
    const tickets = ref(0);
    const orgTokens = ref(0);
    const loader: Ref<any> = ref(null);
    const currentTab: Ref<
      | 'my-programs'
      | 'citizen-id'
      | 'balance'
      | 'payment'
      | 'referral'
      | 'loading'
      | 'settings'
      | 'badges',
      'profile' | 'sponsor'
    > = ref(
      [
        'my-programs',
        'citizen-id',
        'balance',
        'payment',
        'referral',
        'loading',
        'settings',
        'badges',
        'profile',
        'sponsor'
      ].includes(context.root._route.params.component)
        ? context.root._route.params.component
        : 'my-programs'
    );
    const currentProfile: Ref<keyof typeof citizenComponents> = ref('general-id');
    const IDs = ref({
      Employer: 'purple',
      Organizer: 'purple',
      Student: 'green',
      Teacher: 'pink',
      School: 'blue',
      Parent: 'yellow'
    });

    const processQuery = async () => {
      const res = await getTokens();
      if (res) {
        tickets.value = res.personalWallet?.balance;
        orgTokens.value = res.organizationTokens;
      }
    };
    const clickTab = id => {
      currentTab.value = id;
    };
    const clickProfile = (profile: string) => {
      context.root.$router.push({ name: 'citizenProfile' });
      currentProfile.value = `${profile.toLowerCase()}-id` as keyof typeof citizenComponents;
    };
    watch(
      user.value,
      newUser => {
        const types: any = newUser?.userTypes;
        if (types?.includes('School') || types?.includes('Parent')) currentTab.value = 'payment';
        else if (types?.includes('Teacher')) {
          // currentTab.value = 'invite';
        } else currentTab.value = 'my-programs';
        loader.value!.process();
      },
      { deep: true }
    );

    watchEffect(() => {
      if (_props.isWallet) {
        currentTab.value = 'balance';
      }
      if (_props.isPortfolio) {
        currentTab.value = 'my-programs';
      }
      if (_props.isPayment) {
        currentTab.value = 'payment';
      }
      if (_props.isSetting) {
        currentTab.value = 'settings';
      }
      if (_props.isCitizen) {
        currentTab.value = 'citizen-id';
      }
      if (_props.isBadges) {
        currentTab.value = 'badges';
      }
    });

    const showSponsor = ref(process.env.VUE_APP_SHOW_SPONSOR === 'true');

    return {
      currentTab,
      currentProfile,
      clickProfile,
      IDs,
      tickets,
      loader,
      clickTab,
      processQuery,
      user,
      snackbar: true,
      showSponsor,
      orgTokens
    };
  }
};
