/* eslint-disable import/prefer-default-export */
import { ObjectId } from 'bson';
import axios from 'axios';
import type { User } from '../@types/user.d';

export const inviteNewStakeholder = async data => {
  try {
    const res = await axios.post(
      `${process.env.VUE_APP_SPONSOR_SERVICE}/invite-stakeholder`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      }
    );
    return res.data.data;
  } catch (error) {
    return error;
  }
};

export const fetchParticipants = async (
  searchQuery: string,
  skip: number,
  limit: number,
  user_id: string,
  org_id: string,
  type: string,
  participantType: string
): Promise<User[]> => {
  try {
    const res = await axios.get(
      `${process.env.VUE_APP_SPONSOR_SERVICE}/search-participants?query=${searchQuery}&skip=${skip}&limit=${limit}&user_id=${user_id}&org_id=${org_id}&type=${type}&participantType=${participantType}`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return res.data.data;
  } catch (error) {
    return error;
  }
};

export const saveKeywords = async (data: string): Promise<User[]> => {
  try {
    const res = await axios.patch(`${process.env.VUE_APP_SPONSOR_SERVICE}/save-keys`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return res.data.data;
  } catch (error) {
    return error;
  }
};
