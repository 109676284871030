







import { ref, onMounted } from '@vue/composition-api';
import { useUserActions } from '@/store';

export default {
  name: 'PrivacyPolicy',
  setup(props, ctx) {
    const { getPrivacyPolicy } = useUserActions(['getPrivacyPolicy']);
    const privacyPolicy = ref();
    onMounted(async () => {
      const response = await getPrivacyPolicy();
      privacyPolicy.value = response.content;
    });
    return { privacyPolicy };
  }
};
