var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.stakeholderDialog),callback:function ($$v) {_vm.stakeholderDialog=$$v},expression:"stakeholderDialog"}},[_c('v-card',[_c('div',{staticClass:"pl-12 pr-12"},[_c('div',{staticClass:"d-flex justify-end pl-12 pr-12 pt-12"},[_c('v-btn',{attrs:{"x-large":"","icon":"","grey":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"color":"grey","x-large":""}},[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"d-flex justify-start ml-6 mr-6 mb-6"},[_c('v-icon',{attrs:{"color":"red","x-large":""}},[_vm._v("mdi-account-group")])],1),_c('div',{staticClass:"d-flex justify-start ma-6"},[_c('h1',[_vm._v("Manage Stakeholders")])]),_c('div',[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"mobile-employer__employer mr-3",attrs:{"depressed":"","rounded":"","loading":_vm.isGenerating,"color":"grey","icon":"","x-large":""},on:{"click":_vm.generateInviteLink}},[_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-telegram")])]}}])},[_c('span',[_vm._v("Generate Invite Link")])])],1),_c('v-switch',{attrs:{"inset":"","label":_vm.isInvitingByEmail ? 'Invite By Email' : 'Invite By Phone'},model:{value:(_vm.isInvitingByEmail),callback:function ($$v) {_vm.isInvitingByEmail=$$v},expression:"isInvitingByEmail"}})],1),_c('ValidationObserver',{ref:"inviteForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"d-flex",on:{"submit":function($event){$event.preventDefault();return _vm.handleInviteForm.apply(null, arguments)}}},[(_vm.isInvitingByEmail)?_c('validation-provider',{staticClass:"invite-input pr-12",attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Type in email address","placeholder":"friend@email.com","outlined":"","height":"50px","rounded":true},model:{value:(_vm.inviteeEmail),callback:function ($$v) {_vm.inviteeEmail=$$v},expression:"inviteeEmail"}})]}}],null,true)}):_c('ATeleInput',{staticClass:"mr-8 mb-8 tele-input",attrs:{"input-text":"black","is-rounded":true},on:{"get":_vm.getValidation},model:{value:(_vm.phoneState.phone_number),callback:function ($$v) {_vm.$set(_vm.phoneState, "phone_number", $$v)},expression:"phoneState.phone_number"}}),_c('v-btn',{staticClass:"invite-btn",attrs:{"disabled":(_vm.isInvitingByEmail && invalid) ||
                (!_vm.isInvitingByEmail && !_vm.isPhoneValid) ||
                !_vm.permissions.includes('can_manage_org_stakeholders'),"rounded":true,"x-large":"","loading":_vm.isRegistering},on:{"click":_vm.handleInviteForm}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-telegram")]),_vm._v(" Send ")],1)],1)]}}])})],1),_c('div',[_c('h3',[_vm._v("Stakeholders")]),_c('v-data-table',{attrs:{"headers":_vm.memberHeaders,"items":_vm.currentStakeholders,"loading":_vm.isFetching,"items-per-page":15,"hide-default-footer":false},scopedSlots:_vm._u([{key:"item.firstName",fn:function(ref){
                var item = ref.item;
return [_c('h3',[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))])]}},{key:"item.email",fn:function(ref){
                var item = ref.item;
return [_c('h3',[_vm._v(_vm._s(item.email))])]}},{key:"item.role",fn:function(ref){
                var item = ref.item;
return [_c('h3',[_vm._v(_vm._s(_vm.startCase(item.role)))])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [(_vm.canChangeRole)?_c('v-btn',{attrs:{"rounded":"","depressed":"","outlined":""},on:{"click":function($event){return _vm.handleChangeRoleBtnClick(item)}}},[_vm._v("Change Role")]):_vm._e()]}}])})],1),_c('div',{staticClass:"mt-12"},[_c('h3',[_vm._v("Invited Stakeholders")]),_c('v-data-table',{attrs:{"headers":_vm.pendingHeaders,"items":_vm.pendingStakeholders,"loading":_vm.isLoading,"sort-by":"created","items-per-page":10,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('span',{class:_vm.getPendingStakeholderStatusClass(item.status)},[_vm._v(_vm._s(item.status))])]}}])})],1)])]),(!!_vm.selectedUser)?_c('stakeholders-roles-dialog',{attrs:{"user":_vm.selectedUser,"org-roles":_vm.orgRoles,"is-loading":_vm.isChanging,"api-state":_vm.updateStakeholderApiState},on:{"close":function($event){_vm.rolesDialog = false},"update-role":_vm.handleApplyNewRole},model:{value:(_vm.rolesDialog),callback:function ($$v) {_vm.rolesDialog=$$v},expression:"rolesDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }