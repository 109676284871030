var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"sponsor__details d-flex flex-column justify-center"},[_c('div',{staticClass:"sponsor__details-namecard d-flex flex-column align-center"},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-select',{staticClass:"welcome-to-miami font-weight-bold d-flex align-center mb-4 mt-4 ml-12 mr-6",attrs:{"items":_vm.walletList,"item-text":"name","disabled":_vm.isPublic,"return-object":true,"rounded":"","label":"Wallet List","filled":"","hide-details":""},on:{"change":function($event){return _vm.saveOrg(_vm.selectedOrganization)}},model:{value:(_vm.selectedOrganization),callback:function ($$v) {_vm.selectedOrganization=$$v},expression:"selectedOrganization"}},[_c('template',{slot:"append-outer"},[(_vm.isLoading)?_c('v-progress-circular',{attrs:{"size":"25","width":"3","indeterminate":""}}):_vm._e()],1)],2),(_vm.selectedOrganization._id && !_vm.isLoading && (_vm.isPublic || !_vm.isMember))?_c('v-btn',{staticClass:"font-weight-bold white--text",attrs:{"x-large":"","rounded":"","loading":_vm.isAdding,"depressed":"","color":"red"},on:{"click":_vm.setLocalOrganization}},[_vm._v(" Join! ")]):_vm._e(),_c('onboard-dialog',{on:{"input":function($event){return _vm.toggleDialog($event)}},model:{value:(_vm.isOnboarding),callback:function ($$v) {_vm.isOnboarding=$$v},expression:"isOnboarding"}})],1),_c('div',{staticClass:"d-flex justify-end mb-auto"},[_c('v-btn',{staticClass:"d-flex font-weight-bold",attrs:{"rounded":"","disabled":!_vm.organizationPermissions.includes('can_manage_org_stakeholders') ||
            !_vm.selectedOrganization._id ||
            _vm.isPublic,"depressed":"","color":"black","icon":""},on:{"click":function($event){_vm.manageStakeholdersDialog = true}}},[_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-account-group")])]}}])},[_c('span',[_vm._v("Manage Organization")])])],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mobile-employer__employer mb-10 ml-3",attrs:{"depressed":"","disabled":_vm.isPublic || !_vm.permissions.includes('can_create_org'),"rounded":"","color":"grey","icon":""}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-plus")])]}}],null,true)},[_c('span',[_vm._v("Create new organization")])])],1)]}}]),model:{value:(_vm.openNewOrgForm),callback:function ($$v) {_vm.openNewOrgForm=$$v},expression:"openNewOrgForm"}},[_c('v-card',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"ma-2",attrs:{"x-large":"","icon":"","dark":""},on:{"click":function($event){_vm.openNewOrgForm = false}}},[_c('v-icon',{attrs:{"x-large":"","color":"grey"}},[_vm._v("mdi-close")])],1)],1),_c('v-container',{staticClass:"mt-auto mb-auto d-flex justify-center flex-column"},[_c('div',{staticClass:"headline d-flex justify-center font-weight-bold mb-5"},[_vm._v(" Create a new organization ")]),_c('div',{staticClass:"d-flex justify-center align-center mobile-employer__programselect"},[_c('new-organization-form',{ref:"newOrg",on:{"create-new-organization":_vm.handleCreatedNewOrganization}})],1)])],1)],1)],1),_c('ManageStakeholders',{attrs:{"organization":_vm.selectedOrganization,"pending-stakeholders":_vm.pendingStakeholders,"fetch-pending-stakeholders":_vm.loadInitialData,"is-loading":_vm.isLoading,"is-generating":_vm.isGenerating,"generate-invite-link":_vm.generateInviteLink},on:{"close":function($event){_vm.manageStakeholdersDialog = false},"set-org":_vm.setOrganization},model:{value:(_vm.manageStakeholdersDialog),callback:function ($$v) {_vm.manageStakeholdersDialog=$$v},expression:"manageStakeholdersDialog"}})],1),_c('div',{staticClass:"sponsor__details-avatar"},[_c('profile',{attrs:{"size":60}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }