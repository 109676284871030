







import { ref, onMounted } from '@vue/composition-api';
import { useUserActions } from '@/store';

export default {
  name: 'TermsAndConditions',
  setup(props, ctx) {
    const { getTermsAndConditions } = useUserActions(['getTermsAndConditions']);
    const termsAndConditions = ref();
    onMounted(async () => {
      const response = await getTermsAndConditions();
      termsAndConditions.value = response.content;
    });
    return { termsAndConditions };
  }
};
