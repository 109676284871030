




































import { ref } from '@vue/composition-api';
import store from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';
import axios from 'axios';

export default {
  name: 'NewOrganizationForm',

  setup(props, ctx) {
    const isCreating = ref(false);

    const form = ref({
      name: ''
    });

    const handleCreateNewOrganization = async () => {
      try {
        isCreating.value = true;
        const data = {
          name: form.value.name
        };
        const res = await axios.post(`${process.env.VUE_APP_SPONSOR_SERVICE}/create-org`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });

        if (res.data) {
          ctx.emit('create-new-organization', res.data);
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: res.data.message,
            type: 'success',
            isShowSnackbar: true
          });
        }
      } catch (error) {
        isCreating.value = false;
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: error.response.data.error.description,
          type: 'error',
          isShowSnackbar: true
        });
      }
    };

    return {
      handleCreateNewOrganization,
      form,
      isCreating
    };
  }
};
