






import { defineComponent } from '@vue/composition-api';

import GeneralID from './id/GeneralID.vue';
import EmployerID from './id/EmployerID.vue';
import StudentID from './id/StudentID.vue';
import SchoolID from './id/SchoolID.vue';
import ParentID from './id/ParentID.vue';
import TeacherID from './id/TeacherID.vue';
import OrganizerID from './id/OrganizerID.vue';

export default defineComponent({
  name: 'CitizenID',

  components: {
    'general-id': GeneralID,
    'employer-id': EmployerID,
    'student-id': StudentID,
    'teacher-id': TeacherID,
    'school-id': SchoolID,
    'parent-id': ParentID,
    'organizer-id': OrganizerID
  },

  props: {
    expComponent: {
      type: String,
      default: ''
    }
  },

  setup() {}
});
export enum components {
  'general-id' = 'general-id',
  'employer-id' = 'employer-id',
  'student-id' = 'student-id',
  'teacher-id' = 'teacher-id',
  'school-id' = 'school-id',
  'parent-id' = 'parent-id',
  'organizer-id' = 'organizer-id'
}
