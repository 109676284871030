


















































































































































import { OrganizationUser, OrganizationUserType } from '@/@types/organization.d';
import { User } from '@/@types/user.d';
import { defineComponent, ref, Ref, computed, reactive, watch } from '@vue/composition-api';
import startCase from 'lodash/startCase';
import { ApiStatus } from '@/utils/const';
import { inviteNewStakeholder } from '@/api/sponsorApi';
import { updateStakeholder, fetchStakeholders } from '@/api/organizationApi';
import { useUserState, useUserActions, useUserGetters } from '@/store';
import { GetterTypes } from '@/store/modules/auth/getters';
import StakeholdersRolesDialog from './StakeholdersRolesDialog.vue';

export default defineComponent({
  name: 'ManageStakeholders',
  components: {
    StakeholdersRolesDialog,
    ATeleInput: () => import('@/components/atoms/ATeleInput.vue')
  },

  props: {
    value: {
      type: Boolean
    },
    organization: {
      type: Object,
      default: () => {}
    },
    pendingStakeholders: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isGenerating: {
      type: Boolean,
      default: false
    },
    fetchPendingStakeholders: {
      type: Function,
      default: () => {}
    },
    generateInviteLink: {
      type: Function,
      default: () => {}
    }
  },

  setup(props, ctx) {
    const { fetchOrgRoles } = useUserActions(['fetchOrgRoles']);
    const rolesDialog = ref(false);
    const selectedUser: Ref<OrganizationUser | null> = ref(null);
    const updateStakeholderApiState: Ref<ApiStatus> = ref(ApiStatus.Idle);
    const inviteeEmail: Ref<string | null> = ref(null);
    const isInvitingByEmail = ref(true);
    const availableUsers: Ref<User[]> = ref([]);
    const errorMessageDetails: Ref<string | null> = ref(null);
    const isRegistering = ref(false);
    const isChanging = ref(false);
    const currentStakeholders = ref([]);
    const isFetching = ref(false);
    const isPhoneValid = ref(false);
    const pendingHeaders = [
      { text: 'Invitee', value: 'invitee', width: '30%' },
      { text: 'Created', value: 'created', width: '30%' },
      { text: 'Status', value: 'status', width: '30%' }
    ];
    const phoneState = reactive({
      phone_number: '',
      formatted_number: ''
    });

    const stakeholderDialog = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('close');
      }
    });

    const permissions = computed({
      get: () => localStorage?.getItem('permissions')?.split(',') || [],
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    function getValidation(val) {
      if (val && val.countryCallingCode && val.formatted) {
        phoneState.formatted_number = `+${val.countryCallingCode}${val.formatted}`;
        isPhoneValid.value = val.valid;
      } else {
        phoneState.formatted_number = '';
      }
    }

    const memberHeaders = [
      { text: 'Name', value: 'name', width: '25%' },
      { text: 'Email', value: 'email', width: '25%' },
      { text: 'Role', value: 'role', width: '25%' },
      { text: 'Actions', value: 'action', width: '25%' }
    ];

    async function fetchCurrentStakeholders() {
      isFetching.value = true;
      currentStakeholders.value = await fetchStakeholders(props.organization?._id?.toString());
      isFetching.value = false;
    }

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const orgRoles = ref();

    const handleChangeRoleBtnClick = async (user: OrganizationUser) => {
      const orgRolesArr = await fetchOrgRoles();
      if (orgRolesArr) {
        rolesDialog.value = true;
        selectedUser.value = user;
        orgRoles.value = orgRolesArr?.map(role => ({
          label: capitalizeFirstLetter(role?.role?.toLowerCase()),
          value: role?.role?.toLowerCase()
        }));
      }
    };

    const handleApplyNewRole = async (role: OrganizationUserType) => {
      if (!selectedUser.value) return;

      try {
        isChanging.value = true;
        const data = {
          organization_id: props.organization._id,
          user_id: selectedUser.value.user_id,
          role
        };
        const res = await updateStakeholder(data);
        if (res) {
          ctx.emit('set-org', res);
          await fetchCurrentStakeholders();
        }
        rolesDialog.value = false;
        selectedUser.value = null;
        isChanging.value = false;
      } catch {
        isChanging.value = false;
      }
    };

    const currentUser = computed(() => useUserState(['user']).user.value!);

    const currentUserId = computed(() => {
      const {
        getObjectId: { value: userId }
      } = useUserGetters([GetterTypes.getObjectId]);

      return userId;
    });

    const inviteStatus = ref(ApiStatus.Idle);

    const resetInviteForm = () => {
      inviteeEmail.value = null;
    };

    const handleInviteForm = async () => {
      try {
        isRegistering.value = true;
        const data: any = {
          inviter_name: `${currentUser.value?.firstName} ${currentUser.value?.lastName}`,
          inviter_id: currentUserId.value,
          organization_id: props.organization._id,
          organization_name: props.organization.name
        };

        if (isInvitingByEmail.value) {
          data.invitee = inviteeEmail.value;
        } else {
          data.invitee = phoneState.formatted_number;
        }

        const result = await inviteNewStakeholder(data);

        if (result) {
          resetInviteForm();
          props.fetchPendingStakeholders(props.organization._id);
          await fetchCurrentStakeholders();
          isRegistering.value = false;
        }
      } catch {
        isRegistering.value = false;
      }
    };

    const getPendingStakeholderStatusClass = (status: 'pending' | 'accepted' | 'rejected') => {
      if (status === 'pending') {
        return 'orange--text text--darken-3';
      }
      if (status === 'accepted') {
        return 'green--text text--darken-3';
      }
      if (status === 'rejected') {
        return 'red--text text--darken-3';
      }
      return '';
    };

    const currentUserRole = computed(() => {
      const user = props.organization?.users.find(
        (user: OrganizationUser) => user.user_id.toString() === currentUserId.value.toString()
      );

      return user?.role;
    });

    const canChangeRole = computed(() => {
      return currentUserRole.value === 'owner';
    });

    watch(props, val => {
      if (val.value) {
        fetchCurrentStakeholders();
      }
    });

    return {
      isFetching,
      fetchCurrentStakeholders,
      stakeholderDialog,
      currentStakeholders,
      handleChangeRoleBtnClick,
      rolesDialog,
      selectedUser,
      startCase,
      handleApplyNewRole,
      updateStakeholderApiState,
      inviteeEmail,
      inviteStatus,
      ApiStatus,
      handleInviteForm,
      isRegistering,
      getPendingStakeholderStatusClass,
      canChangeRole,
      isInvitingByEmail,
      availableUsers,
      errorMessageDetails,
      pendingHeaders,
      memberHeaders,
      isChanging,
      phoneState,
      isPhoneValid,
      getValidation,
      permissions,
      orgRoles
    };
  }
});
