




















import { ref, watchEffect, computed } from '@vue/composition-api';
import { useUserActions, useUserState } from '@/store';

export default {
  name: 'ConfirmGoogleAuthentication',
  props: {
    code: {
      type: String,
      required: true
    }
  },
  setup(props: any, ctx: any) {
    const { user } = useUserState(['user']);
    const { getCalendarTokenData, fetchUserById, loginUser } = useUserActions([
      'getCalendarTokenData',
      'fetchUserById',
      'loginUser'
    ]);
    const color = ref('grey');
    const email = ref('');
    const displayMessage = ref(`..... Connecting with ${localStorage.getItem('connectionType')}`);
    const confirmationError = ref(false);
    const googleIcon = ref(false);
    if (localStorage.getItem('connectionType') === 'google') {
      googleIcon.value = true;
    }
    const programId: any = computed(() => {
      return localStorage.getItem('programId');
    });
    const documentId: any = computed(() => {
      return localStorage.getItem('documentId');
    });
    const adkIndex = computed(() => {
      return localStorage.getItem('guideAdkIndex');
    });
    const adkType = computed(() => {
      return localStorage.getItem('adkType');
    });
    const redirectUrl = computed(() => {
      return localStorage.getItem('redirectUrl');
    });

    const clearLocalStorage = () => {
      localStorage.removeItem('programId');
      localStorage.removeItem('documentId');
      localStorage.removeItem('adkIndex');
      localStorage.removeItem('adkType');
      localStorage.removeItem('connectionType');
      localStorage.removeItem('isEmployer');
      localStorage.removeItem('guideAdkIndex');
      localStorage.removeItem('redirectUrl');
    };

    watchEffect(async () => {
      if (ctx.root.$route.query.code) {
        if (
          localStorage.getItem('connectionType') === 'google' &&
          ctx.root.$route.query.scope &&
          !ctx.root.$route.query.scope.includes('https://www.googleapis.com/auth/calendar')
        ) {
          window.location.href = `${redirectUrl.value}?isScopeUnchecked=true`;
        } else {
          let document_type = 'Student';
          if (localStorage.getItem('isEmployer') === 'isEmployer') {
            document_type = 'Program';
          }
          const response = await getCalendarTokenData({
            code: ctx.root.$route.query.code,
            type: localStorage.getItem('connectionType'),
            public_id: document_type === 'Student' ? documentId?.value : null,
            document_id: documentId.value,
            document_type,
            adk_index: adkIndex.value,
            adk_type: adkType?.value
          });
          if (response) {
            window.location.href = `${redirectUrl?.value}`;
            clearLocalStorage();
          }
        }
      } else if (ctx.root.$route.query.error) {
        ctx.root.$router.push(`/portfolio`);
      }
    });

    return {
      confirmationError,
      displayMessage,
      color,
      email,
      programId,
      googleIcon
    };
  }
};
