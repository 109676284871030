



















































































































































































































































































import { ref, Ref, computed, watch } from '@vue/composition-api';
import { User } from '@/@types/user.d';
import { Token } from '@/generated/graphql';
import { ActionTypes } from '@/store/modules/tools/actions';
import store, { useUserState, useUserActions } from '@/store';
import { fetchParticipants, saveKeywords } from '@/api/sponsorApi';
// import PersonalWallet from '@/views/Portfolio/views/PersonalWallet.vue';
import axios from 'axios';
import OrganizationHeader from './OrganizationHeader.vue';
import { fetchTokens } from '../../../api/organizationApi';

export default {
  name: 'SponsorHome',

  components: {
    OrganizationHeader
  },

  setup(_, ctx) {
    const sponsorRequestHeaders = [
      { text: 'Student Name', value: 'studentName', width: '20%' },
      { text: 'Program', value: 'programName', width: '20%' },
      { text: 'City', value: 'home', width: '10%' },
      { text: 'School', value: 'school', width: '15%' },
      { text: 'Culture', value: 'ethnicity', width: '20%' },
      { text: 'Conditions', value: 'condition', width: '20%' },
      { text: 'Actions', value: 'actions', sortable: false, width: '10%' }
    ];

    const { user } = useUserState(['user']);
    const tokens: Ref<Token[]> = ref([]);
    const { getTokens } = useUserActions(['getTokens']);
    const colors = ['red', 'orange', 'blue', 'purple', 'pink', 'yellow'];
    const sponsorRoles = ref(false);
    const orgHeader = ref();
    const search: any = ref('');
    const isSearching = ref(false);
    const tokensCount = ref(0);
    const isFetching = ref(false);
    const selectedParticipant: any = ref(null);
    const participantList: Ref<User[]> = ref([]);
    const searchText = ref([]);
    const selectedRequests = ref([]);
    const sponsorLoading = ref(false);
    const sponsorRequests = ref([]);
    const isSaving = ref(false);
    const currentOrg: any = ref({});
    const sponsorDialog = ref(false);
    const sponsorState = ref(false);
    const transferState = ref(false);
    const skip = ref(0);
    const limit = ref(10);
    const totalItems = ref(0);
    const options: any = ref({});
    const isOffering = ref(false);
    const wallet = ref();
    const myWallet: any = ref({});
    const orgTokens = ref(0);
    const originalOwners: Ref<Pick<User, '_id' | 'firstName' | 'lastName'>[]> = ref([]);

    async function fetchOrgTokens(val) {
      isFetching.value = true;
      let response;
      if (val?._id) response = await fetchTokens(val?._id.toString());
      else response = await getTokens();
      if (
        response?.organizationTokens ||
        response?.balance !== undefined ||
        response?.personalWallet
      ) {
        orgTokens.value = response.organizationTokens;
        tokensCount.value = val?._id ? response?.balance : response?.personalWallet?.balance;
        myWallet.value = val?._id ? response : response?.personalWallet;
        isFetching.value = false;
      } else {
        ctx.root.$router.push({ name: 'portfolio' });
      }
    }

    const isPublic = computed(() => {
      if (ctx.root.$route.name === 'pilotcityInvite') {
        return true;
      }
      return false;
    });

    const totalTokens = computed(() => {
      return orgTokens.value + tokensCount.value || 0;
    });

    const list = computed(() => {
      return participantList.value;
    });

    const walletList = computed(() => {
      return orgHeader.value?.walletList;
    });

    const totalSponsoredStds = computed(() => {
      return myWallet.value?.logs?.filter(l => l.studentId)?.length;
    });

    const sponsorName = item =>
      item.sponsorName ? item.sponsorName : `${item.firstName} ${item.lastName}`;

    // UI Management
    const modOriginalOwners = computed(() =>
      originalOwners.value.map(owner => ({
        ...owner,
        color: colors[Math.floor(Math.random() * (colors.length - 1))]
      }))
    );

    const isPersonalWallet = computed(() => {
      if (currentOrg?.value?.value === 'personal_wallet') {
        return true;
      }
      return false;
    });

    const saveKeys = async () => {
      try {
        isSaving.value = true;
        const data: any = {
          organization_id: currentOrg?.value?._id.toString(),
          searchKeys: searchText.value
        };
        await saveKeywords(data);
        isSaving.value = false;
      } catch (e) {
        isSaving.value = false;
      }
    };

    const searchParticipants = async () => {
      try {
        isSearching.value = true;
        const result = await fetchParticipants(
          JSON.stringify(searchText.value),
          skip.value,
          limit.value
        );
        sponsorRequests.value = result.participantList;
        totalItems.value = result.total;
        if (searchText.value.length) await saveKeys();
        isSearching.value = false;
      } catch (e) {
        isSearching.value = false;
      }
    };

    const stakeholderList = computed(() => {
      return (
        orgHeader.value?.selectedOrganization?.users?.map(stk => ({
          ...stk,
          color: colors[Math.floor(Math.random() * (colors.length - 1))]
        })) || []
      );
    });

    async function setOrganization(val) {
      searchText.value = val?.searchKeys || [];
      currentOrg.value = val;
      await searchParticipants();
    }

    function redirectToPayment() {
      if (isPersonalWallet.value) {
        ctx.root.$router.push({ name: 'payment' });
      } else {
        ctx.root.$router.push({
          name: 'payment',
          query: { organization: true, orgId: currentOrg.value._id }
        });
      }
    }

    watch(options, val => {
      if (val && searchText.value.length > 0) {
        limit.value = val.itemsPerPage;
        skip.value = (val.page - 1) * val.itemsPerPage;
        searchParticipants();
      }
    });

    function openOfferDialog(item) {
      selectedParticipant.value = item;
      sponsorDialog.value = true;
    }

    return {
      user,
      sponsorDialog,
      openOfferDialog,
      modOriginalOwners,
      redirectToPayment,
      isPersonalWallet,
      totalSponsoredStds,
      sponsorRoles,
      currentOrg,
      setOrganization,
      tokensCount,
      fetchOrgTokens,
      sponsorRequests,
      selectedParticipant,
      participantList,
      isFetching,
      isSearching,
      walletList,
      search,
      list,
      colors,
      stakeholderList,
      orgTokens,
      orgHeader,
      sponsorRequestHeaders,
      searchText,
      selectedRequests,
      sponsorLoading,
      searchParticipants,
      skip,
      limit,
      options,
      totalItems,
      sponsorState,
      transferState,
      sponsorName,
      wallet,
      isOffering,
      myWallet,
      totalTokens,
      isPublic
    };
  }
};
