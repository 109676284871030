






























































































































/* eslint-disable prefer-destructuring */
import Profile from '@/components/Profile.vue';
import axios from 'axios';
import { ref, watch, computed } from '@vue/composition-api';
import { ActionTypes } from '@/store/modules/tools/actions';
import {
  addStakeholderToOrg,
  fetchStakeholdersInvitation,
  fetchUserOrganizations
} from '@/api/organizationApi';
import store, { useUserState, useUserActions } from '@/store';
import OnboardDialog from '@/components/molecules/m-onboarding.vue';
import NewOrganizationForm from './NewOrganizationForm.vue';
import ManageStakeholders from './ManageStakeholders.vue';

export default {
  name: 'OrganizationHeader',
  components: {
    Profile,
    NewOrganizationForm,
    ManageStakeholders,
    OnboardDialog
  },

  props: {
    staticView: {
      type: Boolean,
      default: false
    },
    isPublic: {
      type: Boolean,
      default: false
    }
  },

  setup(_props, ctx) {
    const openNewOrgForm = ref(false);
    const sponsorRoles = ref(false);
    const isOnboarding = ref(false);
    const manageStakeholdersDialog = ref(false);
    const pendingStakeholders = ref([]);
    const { user } = useUserState(['user']);
    const { getOrganizationByLink, getPermissionsByRole } = useUserActions([
      'getOrganizationByLink',
      'getPermissionsByRole'
    ]);
    const selectedOrganization = ref({});
    const selectedOrganizationName = ref('');
    const isLoading = ref(false);
    const newOrg = ref();
    const isGenerating = ref(false);
    const isAdding = ref(false);
    const organizations = ref([]);

    const walletList = computed(() => {
      const list = [
        ...organizations.value,
        {
          name: `${user?.value?.firstName} ${user?.value?.lastName}'s Wallet / All`,
          value: 'personal_wallet'
        }
      ];
      return list;
    });

    const permissions = computed({
      get: () => localStorage?.getItem('permissions')?.split(',') || [],
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const organizationPermissions: any = ref(
      localStorage?.getItem('OrganizationPermissions')?.split(',') || []
    );

    const isMember = computed(() => {
      return selectedOrganization.value?.users?.find(
        u => u.user_id.toString() === user.value._id.toString()
      );
    });

    const getOrganizations = async () => {
      organizations.value = await fetchUserOrganizations(user.value?._id);
    };

    async function loadOrgData() {
      if (!_props.staticView) {
        const orgName = localStorage.getItem('savedOrg');
        const invitedOrg = JSON.parse(localStorage.getItem('SelectedOrg'));
        isLoading.value = true;
        await getOrganizations();
        isLoading.value = false;
        if (orgName && !orgName?.includes('All')) {
          selectedOrganization.value = organizations.value?.find(o => o.name === orgName);
          selectedOrganizationName.value = orgName;
        } else if (invitedOrg) {
          selectedOrganization.value = organizations.value?.find(
            o => o._id.toString() === invitedOrg._id.toString()
          );
          selectedOrganizationName.value = invitedOrg.name;
          localStorage.removeItem('setRoute');
          localStorage.removeItem('SelectedOrg');
        } else {
          selectedOrganization.value = organizations.value[0] || walletList.value[0];
          selectedOrganizationName.value =
            organizations.value[0]?.name || walletList.value[0]?.name;
        }
      }
    }

    async function loadPublicData() {
      const ENDPOINT =
        process.env.VUE_APP_NODE_ENV === 'production'
          ? 'https://pilotcity.com'
          : 'https://stage.pilotcity.com';
      isLoading.value = true;
      const link = `${ENDPOINT}${ctx.root.$route.path}`;
      const res = await getOrganizationByLink(link);
      selectedOrganization.value = res;
      selectedOrganizationName.value = res?.name;
      organizations.value.push(res);
      isLoading.value = false;
    }

    if (_props.isPublic) {
      loadPublicData();
    } else {
      loadOrgData();
    }

    const handleCreatedNewOrganization = async val => {
      await getOrganizations();
      openNewOrgForm.value = false;
      newOrg.value.isCreating = false;
      selectedOrganization.value = val.data || {};
      selectedOrganizationName.value = val.data.name || '';
    };

    function setOrganization(val) {
      selectedOrganization.value = val;
    }

    function saveOrg(val) {
      localStorage.setItem('savedOrg', val.name);
    }

    async function loadInitialData(val) {
      isLoading.value = true;
      pendingStakeholders.value = await fetchStakeholdersInvitation(val);
      isLoading.value = false;
    }

    watch(selectedOrganization, async val => {
      if (!_props.isPublic) {
        if (val && val?._id) {
          loadInitialData(val?._id);
          selectedOrganizationName.value = val?.name;
        }
      }
      const stakeholderData = val?.users?.find(
        item => item?.user_id?.toString() === user?.value._id.toString()
      );
      if (stakeholderData) {
        const permissionsData = await getPermissionsByRole({
          table: 'OrganizationRoles',
          roles: JSON.stringify([stakeholderData?.role])
        });
        if (permissionsData) {
          localStorage.setItem('OrganizationPermissions', permissionsData);
          organizationPermissions.value = permissionsData;
        }
      }
      ctx.emit('update:stakeholders', val);
      ctx.emit('search-participant', val);
    });

    async function generateInviteLink() {
      try {
        isGenerating.value = true;
        const data = {
          org_id: selectedOrganization.value?._id
        };

        const resp = await axios.patch(
          `${process.env.VUE_APP_SPONSOR_SERVICE}/generate-invite`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );

        if (resp.data) {
          navigator.clipboard.writeText(resp.data?.data?.invitationLink);
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: 'Link copied to clipboard',
            type: 'success',
            isShowSnackbar: true
          });
          isGenerating.value = false;
        }
      } catch (error) {
        console.log(error);
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: 'Something went wrong',
          type: 'error',
          isShowSnackbar: true
        });
        isGenerating.value = false;
      }
    }

    async function setLocalOrganization() {
      if (!localStorage.getItem('apollo-token')) {
        isOnboarding.value = true;
        localStorage.setItem('SelectedOrg', JSON.stringify(selectedOrganization.value));
        localStorage.setItem('setRoute', ctx.root.$route.path);
      } else {
        const data = {
          user_id: user?.value?._id?.toString(),
          organization_ids: [selectedOrganization.value?._id],
          role: 'admin'
        };
        isAdding.value = true;
        await addStakeholderToOrg(data);
        ctx.root.$router.push({ name: 'wallet' });
        isAdding.value = false;
      }
    }

    function toggleDialog(val) {
      isOnboarding.value = val;
    }

    return {
      openNewOrgForm,
      isAdding,
      toggleDialog,
      setLocalOrganization,
      pendingStakeholders,
      generateInviteLink,
      loadOrgData,
      loadInitialData,
      setOrganization,
      isOnboarding,
      sponsorRoles,
      user,
      organizations,
      selectedOrganization,
      handleCreatedNewOrganization,
      manageStakeholdersDialog,
      selectedOrganizationName,
      newOrg,
      isLoading,
      isGenerating,
      saveOrg,
      isMember,
      walletList,
      loadPublicData,
      permissions,
      organizationPermissions,
      getOrganizations
    };
  }
};
